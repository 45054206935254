import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig'; 
import PositionTrainer from './PositionTrainer';
import Navbar from './Navbar';
import ChessboardComponent from '../components/ChessboardComponent'; // Adjust the path as necessary


function MainPage() {
    const [accessibleComponents, setAccessibleComponents] = useState([]);

    useEffect(() => {
        // Function to fetch accessible components
        const fetchAccessibleComponents = async () => {
            try {
                const token = localStorage.getItem('userToken');
                const response = await axios.get('/mainpage/getusercomponents', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                
                if (Array.isArray(response.data)) {
                    setAccessibleComponents(response.data);
                } else {
                    console.error('Response data is not an array:', response.data);
                }
                
            } catch (error) {
                console.error("Error fetching components:", error);
                // Handle errors, e.g., redirect to login if unauthorized
            }
        };

        fetchAccessibleComponents();
    }, []);

    // Rest of your component...
    const renderComponent = (componentName) => {
        switch (componentName) {
            case 'PositionTrainer':
                return <PositionTrainer />;
            // ... add cases for other components ...
            default:
                return null;
        }
    };
    

    return (
        <div >
            <Navbar/>
                <ChessboardComponent
                    id="myChessboard" 
                    position="start" 
                    orientation="white" 
                    className="max-w-[650px] m-2" 
                />  
            {/* Rest of your main page content goes here */}
        </div>

    );  
}

export default MainPage;

