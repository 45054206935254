import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Register from './views/Register';  // <-- Import the Register component.
import Login from './views/Login';  // <-- Import the Login component.
import MainPage from './views/MainPage';  // <-- Import the Main component.
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext.js';
import ProtectedRoute from './components/ProtectedRoute'; // Adjust the path as necessary


function App() {
  return (
    <AuthProvider>
    <Router>
        {/* <Header /> */}
        <Routes>
            <Route path="/register" element={<Register/>} />  {/* <-- Add the route for the Register component. */}
            <Route path="/login" element={<Login/>} />
            <Route path="/mainpage" element={<ProtectedRoute><MainPage/></ProtectedRoute>}/>
        </Routes>

        {/* <Footer /> */}
    </Router>
    </AuthProvider>
  );
}

export default App;
