// Login.js
import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';


function Login() {
    // Check if the user already has a token. TODO - check if the token is still valid from backend
    useEffect(() => {
        const token = localStorage.getItem('userToken');
        if (token) {
            // If a token exists, redirect to the main page
            window.location.href = '/mainpage'; // Adjust the path as necessary
        }
    }, []);

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        axios.post('/api/users/login', formData)
            .then(response => {
                console.log(response);
                // Handle successful login here
                // For example, store the user token, redirect to dashboard etc.
                // Token will be stored locally (instead of session storage)
                localStorage.setItem('userToken', response.data.userToken);
                //Navigate the user to main page
                navigate('/mainpage');  
            })
            .catch(error => {
                console.error("There was an error:", error);
                // Handle error, display a message to the user etc.
                if (error.response && error.response.status === 401) {
                    setErrorMessage("Login failed");
                }

            });

    };

    return (
        <div class="flex justify-center items-center h-screen bg-indigo-600">
            <div class="w-96 p-6 shadow-lg bg-white rounded-md max-w-9/10 -translate-y-8">
                <h1 class="text-3xl block text-center font-semibold"><i class="fa-solid fa-user"></i> Login</h1>
                <hr class="mt-3"/>
                <form onSubmit={handleSubmit}>
                <div class="mt-3">
                    <label for="email" class="block text-base mb-2">Username</label>
                    <input type="email" name="email" class="border w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600" placeholder="Enter Username..." 
                        value={formData.email} onChange={handleChange} required />
                </div>
                <div class="mt-3">
                    <label for="password" class="block text-base mb-2">Password</label>
                    <input type="password" name="password" class="border w-full text-base px-2 py-1 focus:outline-none focus:ring-0 focus:border-gray-600" placeholder="Enter Password..." 
                        value={formData.password} onChange={handleChange} required/>
                </div>
                <div class="mt-3 flex justify-between items-center">
                    <div>
                        <input type="checkbox"/>
                        <label class="px-1">Remember Me</label>
                    </div>
                    <div>
                        <a href="#" class="text-indigo-800 font-semibold">Forgot Password?</a>
                    </div>
                </div>
                <div class="mt-5">
                    <button type="submit" class="border-2 border-indigo-700 bg-indigo-700 text-white py-1 w-full rounded-md hover:bg-transparent hover:text-black-700 font-semibold"><i class="fa-solid fa-right-to-bracket"></i>&nbsp;&nbsp;Login</button>
                </div>
                </form>
                {errorMessage && <p>{errorMessage}</p>}

            </div>
        </div>

    );
}

export default Login;
