import { Avatar, Dropdown, Navbar } from 'flowbite-react';
import logo from '../images/logo192.png';

export default function NavbarMenu() {

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    window.location.href = '/login';
  };

  return (
    <Navbar fluid rounded>
      <div className="flex md:order-2">
        <Dropdown
          arrowIcon={false}
          inline
          label={
            <Avatar alt="User" img={logo} rounded />
          }
        >
          <Dropdown.Header>
            <span className="block truncate text-sm font-medium">UserName</span>
          </Dropdown.Header>
          <Dropdown.Item>Settings</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
        </Dropdown>
      </div>
    </Navbar>

  )
}