import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';


function Register() {

    // Check if the user already has a token. TODO - check if the token is still valid from backend
    useEffect(() => {
        const token = localStorage.getItem('userToken');
        if (token) {
            // If a token exists, redirect to the main page
            window.location.href = '/mainpage'; // Adjust the path as necessary
        }
    }, []);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post('/api/users/register', formData)
            .then(response => {
                console.log(response.data);  // Directly logging the data response
            })
            .catch(error => {
                console.error("There was an error:", error);
            });
    };

    return (
        <div>
            <h2>Register</h2>
            <form onSubmit={handleSubmit}>
                <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required />
                <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required />
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
                <input type="password" name="confirmPassword" placeholder="Confirm Password" value={formData.confirmPassword} onChange={handleChange} required />
                <button type="submit">Register</button>
            </form>
        </div>
    );
}

export default Register;
