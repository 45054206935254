import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setAuthenticated] = useState(() => {
        const token = localStorage.getItem('userToken');
        return !!token;  // Convert to boolean - true if token exists, false otherwise
    });

    // Method to log in the user
    const logIn = (token) => {
        localStorage.setItem('userToken', token);
        setAuthenticated(true);
    };

    // Method to log out the user
    const logOut = () => {
        localStorage.removeItem('userToken');
        setAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, logIn, logOut }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);
