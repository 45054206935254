import React from 'react';
import { Chessboard } from 'react-chessboard';

const ChessboardComponent = ({ 
            id, 
            position, 
            orientation, 
            lightSquareColor, 
            darkSquareColor, 
            squareSize,
            size,
            className
}) => {
    
    return (
        <div class={className}>
            <Chessboard
                id={id}
                position={position}
                orientation={orientation}
                lightSquareColor={lightSquareColor}
                darkSquareColor={darkSquareColor}
                squareSize={squareSize}
                size={size}
            />
        </div>
    );
};

export default ChessboardComponent;